*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: monospace;
  min-height: 100vh;
  width: 100vw;
  background-color: #282c34;
  display: flex;
  justify-content: center;
}

.App-header {
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

p, span {
  text-align: justify;
  font-size: 1rem;
  margin-bottom: 1.2rem;
}

.App-name {
  font-weight: bold;
}

@media (max-width: 768px) {
  .App-header {
    width: 75%;
  }


  h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
  
  p, span {
    text-align: justify;
    font-size: 0.8rem;
    margin-bottom: 1.2rem;
  }

}